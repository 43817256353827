import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import ZkiliAppApi from '../API/ZkiliAppApi';

export const fetchShips = createAsyncThunk('sdeSlice/fetchShips', async () => {
    return ZkiliAppApi.ships();
});

export const fetchSystems = createAsyncThunk('sdeSlice/fetchSystems', async () => {
    return ZkiliAppApi.systems();
});

export interface SdeState {
    ships: any[];
    systems: any[];
    loading: boolean;
}

export const initialSdeState: SdeState = {
    ships: [],
    systems: [],
    loading: false
};

const sdeSLice = createSlice({
    name: 'sdeSLice',
    initialState: initialSdeState,
    reducers: {},
    extraReducers: builder => {
        builder.addCase(fetchShips.pending, (state, action) => {
            state.loading = true;
        });
        builder.addCase(fetchShips.fulfilled, (state, action) => {
            state.loading = false;
            state.ships = action.payload;
        });
        builder.addCase(fetchSystems.pending, (state, action) => {
            state.loading = true;
        });
        builder.addCase(fetchSystems.fulfilled, (state, action) => {
            state.loading = false;
            state.systems = action.payload;
        });
    }
});

// export const {} = sdeSLice.actions;

export default sdeSLice.reducer;
