import React, { useEffect } from 'react';
import { openSnackbar } from '../Slices/snackbarSlice';
import { useLocation } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import makeStyles from '@mui/styles/makeStyles';
import AppBar from '@mui/material/AppBar';
import Grid from '@mui/material/Grid';
import { Link } from 'react-router-dom';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Toolbar from '@mui/material/Toolbar';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Avatar from '@mui/material/Avatar';

import MenuIcon from '@mui/icons-material/Menu';
import LoginIcon from '@mui/icons-material/Login';
import ConnectedIcon from '@mui/icons-material/WifiTethering';
import DisconnectedIcon from '../Images/SVGIcons/WifiTetheringOff';

import { toggleDrawer } from '../Slices/navigationSlice';
import { checkBotStatus } from '../Slices/botConnectionSlice';

const lightColor = 'rgba(255, 255, 255, 0.7)';

const useStyles = makeStyles(theme => ({
    appBar: {
        height: 48
    },
    secondaryBar: {
        zIndex: 0
    },
    menuButton: {
        marginLeft: -theme.spacing(1)
    },
    iconButtonAvatar: {
        padding: 4
    },
    link: {
        textDecoration: 'none',
        color: lightColor,
        '&:hover': {
            color: theme.palette.common.white
        }
    },
    button: {
        borderColor: lightColor
    }
}));

const Header = ({ title, tabs = false }) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const location = useLocation();
    const botConnection = useSelector(state => state.botConnection);
    const user = useSelector(state => state.user.loggedIn);
    const onDrawerToggle = () => dispatch(toggleDrawer());

    const isBotConnected = botConnection.connected;
    const isBotChecked = botConnection.checked;

    const ConnectionIcon = isBotConnected ? ConnectedIcon : DisconnectedIcon;
    const connectionColor = isBotConnected === false && isBotChecked ? 'secondary' : 'inherit';

    useEffect(() => {
        dispatch(checkBotStatus());
    }, []);

    const onBotStatusClick = () => {
        dispatch(checkBotStatus());
        dispatch(openSnackbar('Checking bot connection status'));
    };

    let selectedTab = 0;
    if (tabs) {
        // find the selected tab
        selectedTab = tabs.findIndex(tab => tab.to === location.pathname || location.pathname.indexOf(tab.to) > -1);

        // Default back to 0 if not found
        if (selectedTab === -1) selectedTab = 0;
    }

    return (
        <React.Fragment>
            <AppBar color="primary" position="sticky" elevation={0} className={classes.appBar}>
                <Toolbar>
                    <Grid container spacing={1} alignItems="center">
                        <Grid item>
                            <IconButton
                                color="inherit"
                                aria-label="open drawer"
                                onClick={onDrawerToggle}
                                className={classes.menuButton}
                                size="large"
                            >
                                <MenuIcon />
                            </IconButton>
                        </Grid>
                        <Grid item xs>
                            <Typography color="inherit" variant="h5" component="h1">
                                {title}
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Tooltip title="Bot connection status">
                                <span>
                                    <IconButton
                                        color="inherit"
                                        onClick={onBotStatusClick}
                                        disabled={botConnection.checking}
                                        size="large"
                                    >
                                        <ConnectionIcon color={connectionColor} />
                                    </IconButton>
                                </span>
                            </Tooltip>
                        </Grid>
                        <Grid item>
                            {user ? (
                                <IconButton
                                    color="inherit"
                                    className={classes.iconButtonAvatar}
                                    size="large"
                                    href={`${process.env.REACT_APP_API_BASE}/api/auth/logout`}
                                >
                                    <Avatar
                                        src={`https://images.evetech.net/characters/${user.id}/portrait?size=64`}
                                        alt="User Avatar"
                                    />
                                </IconButton>
                            ) : (
                                <IconButton
                                    color="inherit"
                                    className={classes.iconButtonAvatar}
                                    size="large"
                                    href={`${process.env.REACT_APP_API_BASE}/api/auth/eve-login`}
                                >
                                    <LoginIcon />
                                </IconButton>
                            )}
                        </Grid>
                    </Grid>
                </Toolbar>
            </AppBar>
            {tabs && (
                <AppBar
                    component="div"
                    className={classes.secondaryBar}
                    color="primary"
                    position="static"
                    elevation={0}
                >
                    <Tabs value={selectedTab} textColor="inherit">
                        {tabs.map(tab => {
                            return (
                                <Tab
                                    textColor="inherit"
                                    component={Link}
                                    to={tab.to}
                                    label={tab.label}
                                    key={tab.label}
                                />
                            );
                        })}
                    </Tabs>
                </AppBar>
            )}
        </React.Fragment>
    );
};

Header.propTypes = {};

export default Header;
