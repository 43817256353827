import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import ZkiliAppApi from '../API/ZkiliAppApi';

export const checkBotStatus = createAsyncThunk('botConnection/checkBotStatus', async () => {
    return ZkiliAppApi.connectionStatus();
});

export interface BotConnectionState {
    connected: boolean;
    checking: boolean;
    checked: boolean;
}

export const initialBotConnectionState: BotConnectionState = {
    connected: false,
    checking: false,
    checked: false
};

const botConnectionSlice = createSlice({
    name: 'botConnection',
    initialState: initialBotConnectionState,
    reducers: {
        toggleChecked(state) {
            state.checked = !state.checked;
        }
    },
    extraReducers: builder => {
        builder.addCase(checkBotStatus.pending, (state, action) => {
            state.checking = true;
        });
        builder.addCase(checkBotStatus.fulfilled, (state, action) => {
            state.checked = true;
            state.checking = false;
            state.connected = action.payload;
        });
    }
});

export const { toggleChecked } = botConnectionSlice.actions;

export default botConnectionSlice.reducer;
