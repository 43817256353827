import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import TimeAgo from 'javascript-time-ago';

import en from 'javascript-time-ago/locale/en';

import './App.scss';

import siteConfig from './Config/site';
import MuiTheme from './Config/MuiTheme';

import ReduxStore from './ReduxStore';
import Content from './Content';

try {
    TimeAgo.addDefaultLocale(en);
} catch (error) {}

const App = () => {
    return (
        <Provider store={ReduxStore}>
            <BrowserRouter basename={siteConfig.baseName} forceRefresh={false}>
                <StyledEngineProvider injectFirst>
                    <ThemeProvider theme={MuiTheme}>
                        <Content />
                    </ThemeProvider>
                </StyledEngineProvider>
            </BrowserRouter>
        </Provider>
    );
};

export default App;
