import { createSlice } from '@reduxjs/toolkit';

let isBigScreen = false;
if (typeof window !== 'undefined') {
    isBigScreen = window.innerWidth >= 1200;
}

export interface NavigationState {
    open: boolean;
}

export const initialNavigationState: NavigationState = {
    open: isBigScreen
};

const drawerSlice = createSlice({
    name: 'navigation',
    initialState: initialNavigationState,
    reducers: {
        toggleDrawer(state) {
            state.open = !state.open;
        }
    }
});

export const { toggleDrawer } = drawerSlice.actions;

export default drawerSlice.reducer;
