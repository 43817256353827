import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';

import Page from '../../Components/Page';
import SEO from '../../Components/SEO';

import logoImage from '../../Images/logo-192x192.png';

const useStyles = makeStyles(theme => ({
    notFoundOuter: {
        height: '90vh'
    },
    notFoundInner: {
        height: '90vh',
        textAlign: 'center'
    },
    button: {
        marginTop: theme.spacing(2)
    }
}));

const Home = () => {
    const classes = useStyles();

    return (
        <Page title="Page not found">
            <SEO title="404 page" description="This page was not found." image={logoImage} />

            <Grid container spacing={2} justifyContent="center" alignItems="center" className={classes.notFoundOuter}>
                <Grid item xs={12} md={5} lg={4} xl={2} className={classes.notFoundInner}>
                    <Typography variant="h4" color="secondary" align="center">
                        Page not found.
                    </Typography>
                </Grid>
            </Grid>
        </Page>
    );
};

export default Home;
