import { createSlice } from '@reduxjs/toolkit';

export const STORED_CHARACTERS = 'stored-characters';

export interface CharacterStatus {
    current: number;
    active: number | false;
}

const saveCharacters = characters => {
    localStorage.setItem(STORED_CHARACTERS, JSON.stringify(characters));
};

export interface UniqueKillsDataState {
    characters: any;
    characterKills: any;
    charactersStatus: {
        [key: string | number]: CharacterStatus;
    };
    active: boolean;
}

export const initialUniqueKillsDataState: UniqueKillsDataState = {
    characters: {},
    characterKills: {},
    charactersStatus: {},
    active: false
};

const uniqueKillsDataSlice = createSlice({
    name: 'uniqueKillsData',
    initialState: initialUniqueKillsDataState,
    reducers: {
        addKillData(state, action) {
            const { characterId, kills } = action.payload;
            // Set initial empty list
            if (!state.characterKills[characterId]) state.characterKills[characterId] = [];

            const filteredKills = kills.map(kill => {
                return {
                    id: kill.killmail_id,
                    value: kill.zkb.totalValue,
                    solo: kill.zkb.solo,
                    points: kill.zkb.points
                };
            });

            // Add kills to the list
            state.characterKills[characterId] = [...state.characterKills[characterId], ...filteredKills];

            if (kills && kills.length === 200) {
                // Go next page
                state.charactersStatus[characterId] = {
                    ...state.charactersStatus[characterId],
                    current: state.charactersStatus[characterId].current + 1
                };
            } else {
                // End the checks
                state.charactersStatus[characterId] = {
                    ...state.charactersStatus[characterId],
                    active: false
                };
            }
        },
        resetKillData(state, action) {
            state.characterKills = {};
        },

        setCharacters(state, action) {
            state.characters = action.payload;
            saveCharacters(state.characters);
        },
        addCharacter(state, action) {
            const { characterId, chracterName } = action.payload;

            state.characters[characterId] = chracterName;
            saveCharacters(state.characters);
        },
        removeCharacter(state, action) {
            delete state.characters[action.payload];
            saveCharacters(state.characters);
        },

        setCharactersStatus(state, action) {
            const { characterId, status } = action.payload;

            state.charactersStatus[characterId] = status;
        },
        resetCharactersData(state) {
            state.charactersStatus = {};
            state.characterKills = {};
        },
        removeCharactersStatus(state, action) {
            delete state.charactersStatus[action.payload];
        },

        setActive(state, action) {
            state.active = action.payload;
        }
    }
});

export const {
    addKillData,
    resetKillData,

    setCharacters,
    addCharacter,
    removeCharacter,

    setCharactersStatus,
    resetCharactersData,
    removeCharactersStatus,

    setActive
} = uniqueKillsDataSlice.actions;

export default uniqueKillsDataSlice.reducer;
