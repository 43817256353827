import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { createLogger } from 'redux-logger';

import botConnection, { BotConnectionState } from './Slices/botConnectionSlice';
import navigation, { NavigationState } from './Slices/navigationSlice';
import packValues, { PackValuesState } from './Slices/packValuesSlice';
import snackbar, { SnackbarState } from './Slices/snackbarSlice';
import sde, { SdeState } from './Slices/sdeSlice';
import uniqueKillsData, { UniqueKillsDataState } from './Slices/uniqueKillsDataSlice';
import user, { UserState } from './Slices/userSlice';

export interface RootState {
    botConnection: BotConnectionState;
    navigation: NavigationState;
    packValues: PackValuesState;
    snackbar: SnackbarState;
    sde: SdeState;
    uniqueKillsData: UniqueKillsDataState;
    user: UserState;
}

const rootReducer = combineReducers({
    botConnection,
    navigation,
    snackbar,
    packValues,
    sde,
    uniqueKillsData,
    user
});

const storeConfig: any = {
    reducer: rootReducer
};

if (process.env.NODE_ENV === 'development') {
    storeConfig.middleware = getDefaultMiddleware =>
        getDefaultMiddleware().concat(
            createLogger({
                collapsed: true
            })
        );
}

const ReduxStore = configureStore(storeConfig);

export default ReduxStore;
