import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import ZkiliAppApi, { JaniceApiItemInfo, PackValueListItem, SupportedCurrencyKey } from '../API/ZkiliAppApi';

export const getPackValues = createAsyncThunk('packValues/getPackValues', async () => {
    return ZkiliAppApi.packValuesList();
});

export const getOmegaCosts = createAsyncThunk('packValues/getOmegaCosts', async () => {
    return ZkiliAppApi.omegaCosts();
});

export const getBaseItemPrices = createAsyncThunk('packValues/getBaseItemPrices', async () => {
    return ZkiliAppApi.itemPrices();
});

export const getCurrencyData = createAsyncThunk('packValues/getCurrencyData', async () => {
    return ZkiliAppApi.currencyData();
});

export const getCerebralAccelerators = createAsyncThunk('packValues/getCerebralAccelerators', async () => {
    return ZkiliAppApi.cerebralAcceleratorList();
});

interface CurrencyData {
    [key: string]: any;
}

export interface PackValuesState {
    loading: boolean;
    omegaLoading: boolean;
    itemsLoading: boolean;
    packs: PackValueListItem[];
    itemPrices: JaniceApiItemInfo[];
    omegaCosts: {
        [key: number]: {
            month: number;
            prices: {
                [key in SupportedCurrencyKey]: number;
            };
        };
    };
    currenciesLoading: boolean;
    currencies: CurrencyData;
    cerebralAcceleratorsLoading: boolean;
    cerebralAccelerators: any;
}

export const initialPackValuesState: PackValuesState = {
    loading: false,
    packs: [],
    omegaLoading: false,
    omegaCosts: [],
    itemsLoading: false,
    itemPrices: [],
    currenciesLoading: false,
    currencies: {},
    cerebralAcceleratorsLoading: false,
    cerebralAccelerators: []
};

const packValuesSlice = createSlice({
    name: 'packValues',
    initialState: initialPackValuesState,
    reducers: {},
    extraReducers: builder => {
        builder.addCase(getPackValues.pending, (state, action) => {
            state.loading = true;
        });
        builder.addCase(getPackValues.fulfilled, (state, action) => {
            state.loading = false;
            state.packs = action.payload;
        });
        builder.addCase(getOmegaCosts.pending, (state, action) => {
            state.omegaLoading = true;
        });
        builder.addCase(getOmegaCosts.fulfilled, (state, action) => {
            state.omegaLoading = false;
            state.omegaCosts = action.payload;
        });
        builder.addCase(getBaseItemPrices.pending, (state, action) => {
            state.itemsLoading = true;
        });
        builder.addCase(getBaseItemPrices.fulfilled, (state, action) => {
            state.itemsLoading = false;
            state.itemPrices = action.payload;
        });
        builder.addCase(getCurrencyData.pending, (state, action) => {
            state.currenciesLoading = true;
        });
        builder.addCase(getCurrencyData.fulfilled, (state, action) => {
            state.currenciesLoading = false;
            state.currencies = action.payload;
        });
        builder.addCase(getCerebralAccelerators.pending, (state, action) => {
            state.cerebralAcceleratorsLoading = true;
        });
        builder.addCase(getCerebralAccelerators.fulfilled, (state, action) => {
            state.cerebralAcceleratorsLoading = false;
            state.cerebralAccelerators = action.payload;
        });
    }
});

// export const {} = packValuesSlice.actions;

export default packValuesSlice.reducer;
