import React, { useEffect } from 'react';

import { useSelector, useDispatch } from 'react-redux';
import makeStyles from '@mui/styles/makeStyles';
import Snackbar from '@mui/material/Snackbar';
import CssBaseline from '@mui/material/CssBaseline';
import Hidden from '@mui/material/Hidden';

import LinkIcon from '@mui/icons-material/Link';
import CalculateIcon from '@mui/icons-material/Calculate';
import PaymentIcon from '@mui/icons-material/Payment';
import EmailIcon from '@mui/icons-material/Email';
import BoostIcon from '@mui/icons-material/ElectricBolt';

import './App.scss';

import Routes from './Routes';

import useSnackbar from './Hooks/useSnackbar';
import { toggleDrawer } from './Slices/navigationSlice';

import SEO from './Components/SEO';
import Drawer from './Components/Drawer';

import logoImage from './Images/logo-192x192.png';
import { checkUser } from './Slices/userSlice';

const categories = [
    {
        id: 'Dashboard',
        children: [
            { id: 'Zkiliboard links', url: '/zkili-links', icon: <LinkIcon /> },
            { id: 'Unique Kills', url: '/unique-kills', icon: <CalculateIcon /> },
            // { id: 'Pack Values', url: '/pack-values', icon: <PaymentIcon /> },
            // { id: 'Cerebral Accelerators', url: '/cerebral-accelerator', icon: <BoostIcon /> }
        ]
    }
];

const bottomCategories = [
    {
        id: 'Bottom',
        hideTitle: true,
        children: [{ id: 'Contact', href: 'https://discord.gg/b8DWFhRbRh', icon: <EmailIcon /> }]
    }
];

const drawerWidth = 256;

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        minHeight: '100vh'
    },
    hiddenLink: {
        display: 'none'
    },
    drawer: {
        transition: 'width 200ms',
        [theme.breakpoints.up('sm')]: {
            width: ({ drawerOpen }) => (drawerOpen ? drawerWidth : 0),
            flexShrink: 0
        }
    },
    app: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column'
    },
    footer: {
        padding: theme.spacing(2)
    }
}));

const Content = () => {
    const dispatch = useDispatch();
    const onDrawerToggle = () => dispatch(toggleDrawer());
    const drawerOpen = useSelector(state => state.navigation.open);
    const { message, openSnackbar, closeSnackbar } = useSnackbar();

    const classes = useStyles({ drawerOpen });

    if (window) window.openSnackbar = openSnackbar;

    useEffect(() => {
        dispatch(checkUser());
    }, []);

    return (
        <div className={classes.root}>
            <SEO path="/" description="The el'miner redirect service for elite pvpers and simps" image={logoImage} />

            <CssBaseline />

            <Snackbar
                autoHideDuration={5000}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right'
                }}
                open={!!message}
                message={message}
                onClose={closeSnackbar}
            />

            <nav className={classes.drawer}>
                <Hidden smUp implementation="js">
                    <Drawer
                        categories={categories}
                        bottomCategories={bottomCategories}
                        PaperProps={{ style: { width: drawerWidth } }}
                        variant="temporary"
                        open={drawerOpen}
                        onClose={onDrawerToggle}
                    />
                </Hidden>
                <Hidden smDown implementation="css">
                    <Drawer
                        categories={categories}
                        bottomCategories={bottomCategories}
                        PaperProps={{ style: { width: drawerWidth } }}
                        variant="persistent"
                        open={drawerOpen}
                        onClose={onDrawerToggle}
                    />
                </Hidden>
            </nav>
            <div className={classes.app}>
                <Routes />
            </div>
        </div>
    );
};

export default Content;
