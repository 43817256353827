import React from 'react';
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router';
import makeStyles from '@mui/styles/makeStyles';
import Divider from '@mui/material/Divider';
import MUIDrawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

import HomeIcon from '@mui/icons-material/Home';

const useStyles = makeStyles(theme => ({
    categoryHeader: {
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2)
    },
    categoryHeaderPrimary: {
        color: theme.palette.common.white
    },
    item: {
        paddingTop: 1,
        paddingBottom: 1,
        color: 'rgba(255, 255, 255, 0.7)',
        '&:hover,&:focus': {
            backgroundColor: 'rgba(255, 255, 255, 0.08)'
        }
    },
    itemCategory: {
        backgroundColor: '#232f3e',
        boxShadow: '0 -1px 0 #404854 inset',
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2)
    },
    firebase: {
        fontSize: 24,
        color: theme.palette.common.white
    },
    itemActiveItem: {
        color: '#4fc3f7'
    },
    itemPrimary: {
        fontSize: 'inherit'
    },
    itemIcon: {
        minWidth: 'auto',
        marginRight: theme.spacing(2)
    },
    divider: {
        marginTop: theme.spacing(2)
    }
}));

const Drawer = ({ categories, bottomCategories, ...other }) => {
    const classes = useStyles();
    const location = useLocation();

    const ListItems = ({ categories }) => {
        return categories.map(({ id, children, hideTitle = false }) => (
            <React.Fragment key={id}>
                {hideTitle === false ? (
                    <ListItem className={classes.categoryHeader}>
                        <ListItemText
                            classes={{
                                primary: classes.categoryHeaderPrimary
                            }}
                        >
                            {id}
                        </ListItemText>
                    </ListItem>
                ) : (
                    <div className={classes.divider} />
                )}
                {children.map(({ id: childId, icon, url, href }) => {
                    const props = { key: childId, button: true };
                    if (url) {
                        props.component = Link;
                        props.to = url;
                    } else if (href) {
                        props.component = 'a';
                        props.href = href;
                        props.target = '_blank';
                        props.rel = 'noreferrer';
                    } else {
                        props.disabled = true;
                    }
                    const active = location.pathname.indexOf(url) === 0;

                    return (
                        <ListItem {...props} className={clsx(classes.item, active && classes.itemActiveItem)}>
                            <ListItemIcon className={classes.itemIcon}>{icon}</ListItemIcon>
                            <ListItemText
                                classes={{
                                    primary: classes.itemPrimary
                                }}
                            >
                                {childId}
                            </ListItemText>
                        </ListItem>
                    );
                })}

                <Divider className={classes.divider} />
            </React.Fragment>
        ));
    };

    return (
        <MUIDrawer variant="permanent" {...other}>
            <List disablePadding>
                <ListItem className={clsx(classes.firebase, classes.item, classes.itemCategory)}>Zkili.app</ListItem>
                <ListItem className={clsx(classes.item, classes.itemCategory)} component={Link} to="/">
                    <ListItemIcon className={classes.itemIcon}>
                        <HomeIcon />
                    </ListItemIcon>
                    <ListItemText
                        classes={{
                            primary: classes.itemPrimary
                        }}
                    >
                        Home
                    </ListItemText>
                </ListItem>
                <ListItems categories={categories} />
            </List>
            <div style={{ flexGrow: 1 }} />
            <List disablePadding>
                <ListItems categories={bottomCategories} />
            </List>
        </MUIDrawer>
    );
};

Drawer.propTypes = {};

export default Drawer;
