import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import AuthApi from '../API/AuthApi';

export const checkUser = createAsyncThunk('userSlice/checkUser', async () => {
    return AuthApi.checkUser();
});

export interface UserInfo {
    id: number;
    name: string;
}

export interface UserState {
    loggedIn: false | null | UserInfo;
    loading: boolean;
}

export const initialUserState: UserState = {
    loggedIn: false,
    loading: false
};

const userSlice = createSlice({
    name: 'userSlice',
    initialState: initialUserState,
    reducers: {
        setUser: (state, action) => {
            state.loggedIn = action.payload;
        }
    },
    extraReducers: builder => {
        builder.addCase(checkUser.pending, (state, action) => {
            state.loading = true;
        });
        builder.addCase(checkUser.fulfilled, (state, action) => {
            state.loading = false;
            state.loggedIn = action.payload;
        });
    }
});

export const { setUser } = userSlice.actions;

export default userSlice.reducer;
