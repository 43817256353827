import { useDispatch, useSelector } from 'react-redux';
import { closeSnackbar, openSnackbar } from '../Slices/snackbarSlice';

const useSnackbar = () => {
    const dispatch = useDispatch();
    const message = useSelector(state => state.snackbar.message);

    const open = message => dispatch(openSnackbar(message));
    const close = () => dispatch(closeSnackbar());

    return { message, openSnackbar: open, closeSnackbar: close };
};

export default useSnackbar;
