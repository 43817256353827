import axios from 'axios';

export type SupportedCurrencyKey = 'eur' | 'usd';

export type SupportedBoosters = 'Expert Cerebral Accelerator' | 'Prototype Cerebral Accelerator';

export interface JaniceApiItemInfo {
    date: string;
    market: {
        id: 2;
        name: 'Jita 4-4';
    };
    buyOrderCount: number;
    buyVolume: number;
    sellOrderCount: number;
    sellVolume: number;
    immediatePrices: {
        buyPrice: number;
        splitPrice: number;
        sellPrice: number;
        buyPrice5DayMedian: number;
        splitPrice5DayMedian: number;
        sellPrice5DayMedian: number;
        buyPrice30DayMedian: number;
        splitPrice30DayMedian: number;
        sellPrice30DayMedian: number;
    };
    top5AveragePrices: {
        buyPrice: number;
        splitPrice: number;
        sellPrice: number;
        buyPrice5DayMedian: number;
        splitPrice5DayMedian: number;
        sellPrice5DayMedian: number;
        buyPrice30DayMedian: number;
        splitPrice30DayMedian: number;
        sellPrice30DayMedian: number;
    };
    itemType: {
        eid: number;
        name: string;
        volume: number;
        packagedVolume: number;
    };
}

export interface MinimalItemInfo {
    buy: number;
    split: number;
    sell: number;
    eid: number;
}

export interface PackValueListSubItem {
    name: string;
    amount: number;
    info: MinimalItemInfo;
}

export interface PackValueListItem {
    name: string;
    omegaDays: number;
    directSp: number;
    is3rdParty?: boolean;
    boosters: {
        [key in SupportedBoosters]: {
            amount: number;
            baseSp: number;
            info?: string;
        };
    };
    prices: {
        [key in SupportedCurrencyKey]: number;
    };
    items: PackValueListSubItem[];
    link?: string;
}

export interface CerebralAcceleratorInfo {
    speed: number;
    duration: number;
    baseSp: number;
}

export interface CerebralAcceleratorListItem {
    priceInfo: JaniceApiItemInfo;
    boosterInfo: CerebralAcceleratorInfo;
}

const ZkiliAppApi = {
    zkiliRedirectStats: async () => {
        const zkiliData = await axios
            .get(`${process.env.REACT_APP_API_BASE}/api/zkili-redirects/stats`, { withCredentials: true })
            .then(response => response.data);

        if (!zkiliData || !zkiliData.counterData) {
            throw new Error('Failed to load Zkili redirect data');
        }

        return zkiliData;
    },

    connectionStatus: async () => {
        try {
            const response = await axios
                .get(`${process.env.REACT_APP_API_BASE}/api/zkili-bot/health`, { withCredentials: true })
                .then(response => response.data);

            return !!response;
        } catch (error) {}
        return false;
    },

    packValuesList: async (): Promise<PackValueListItem[]> => {
        const packValues = await axios
            .get(`${process.env.REACT_APP_API_BASE}/api/pack-values/list`, { withCredentials: true })
            .then(response => response.data);

        return packValues;
    },

    cerebralAcceleratorList: async (): Promise<CerebralAcceleratorListItem[]> => {
        const cerebralAccelerators = await axios
            .get(`${process.env.REACT_APP_API_BASE}/api/pack-values/accelerators`, { withCredentials: true })
            .then(response => response.data);

        return cerebralAccelerators;
    },

    itemPrices: async (): Promise<JaniceApiItemInfo[]> => {
        const packValues = await axios
            .get(`${process.env.REACT_APP_API_BASE}/api/pack-values/prices`, { withCredentials: true })
            .then(response => response.data);

        return packValues;
    },

    currencyData: async (): Promise<JaniceApiItemInfo[]> => {
        const packValues = await axios
            .get(`${process.env.REACT_APP_API_BASE}/api/pack-values/currencies`, { withCredentials: true })
            .then(response => response.data);

        return packValues;
    },

    omegaCosts: async (): Promise<any> => {
        const packValues = await axios
            .get(`${process.env.REACT_APP_API_BASE}/api/pack-values/omega`, { withCredentials: true })
            .then(response => response.data);

        return packValues;
    },

    ships: async (): Promise<any> => {
        const ships = await axios
            .get(`${process.env.REACT_APP_API_BASE}/api/sde/ships`, { withCredentials: true })
            .then(response => response.data);

        return ships;
    },

    systems: async (): Promise<any> => {
        const systems = await axios
            .get(`${process.env.REACT_APP_API_BASE}/api/sde/systems`, { withCredentials: true })
            .then(response => response.data);

        return systems;
    },

    groups: async (): Promise<any> => {
        const groups = await axios
            .get(`${process.env.REACT_APP_API_BASE}/api/sde/groups`, { withCredentials: true })
            .then(response => response.data);

        return groups;
    }
};

export default ZkiliAppApi;
