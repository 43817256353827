const routes = {
    Home: { path: '/' },
    ZkiliLinks: { path: '/zkili-links/:tab?', subPaths: ['/links', '/history', '/custom'] },
    EveLogin: { path: '/auth/eve-callback' },
    UniqueKills: { path: '/unique-kills' },
    // PackValues: { path: '/pack-values' },
    // CerebralAccelerator: { path: '/cerebral-accelerator' },
    NotFound: { path: '/notfound', ignoreSitemap: true }
};

module.exports = routes;
