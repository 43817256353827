import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import Header from './Header';

const useStyles = makeStyles(theme => ({
    main: {
        flex: 1,
        padding: theme.spacing(2, 2),
        // overflowY: 'scroll',
        height: 'calc(100vh - 48px)'
    }
}));

const Page = ({ children, ...props }) => {
    const classes = useStyles();

    return (
        <>
            <Header {...props} />
            <main className={classes.main}>{children}</main>
        </>
    );
};

Page.propTypes = {};

export default Page;
