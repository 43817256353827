import axios from 'axios';

const ZkiliAppApi = {
    checkUser: async () => {
        const userInfo = await axios
            .get(`${process.env.REACT_APP_API_BASE}/api/auth/validate`, { withCredentials: true })
            .then(response => response.data);

        if (userInfo && userInfo.id) {
            return userInfo;
        }

        return null;
    }
};

export default ZkiliAppApi;
