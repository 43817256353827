import { createSlice } from '@reduxjs/toolkit';

export interface SnackbarState {
    message: false | string;
}

export const initialSnackbarState: SnackbarState = {
    message: false
};

const drawerSlice = createSlice({
    name: 'snackbar',
    initialState: initialSnackbarState,
    reducers: {
        closeSnackbar(state) {
            state.message = false;
        },
        openSnackbar(state, action) {
            state.message = action.payload;
        }
    }
});

export const { closeSnackbar, openSnackbar } = drawerSlice.actions;

export default drawerSlice.reducer;
